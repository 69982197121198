import { useRef } from "react";

import { isDOM } from "@/lib/isDom";

// Thanks https://gist.github.com/reecelucas/2f510e6b8504008deaaa52732202d2da
const safeDocument: Document | null = isDOM ? document : null;

/**
 * Usage:
 * const [blockBodyScroll, allowBodyScroll] = useBodyScrollBlock();
 */
export const useBodyScrollBlock = (): [() => void, () => void] => {
  const scrollBlocked = useRef(false);

  if (!safeDocument) {
    return [() => {}, () => {}];
  }

  const html = safeDocument.documentElement;
  const { body } = safeDocument;

  const blockBodyScroll = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!body || !body.style || scrollBlocked.current) {
      return;
    }

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      Number.parseInt(
        window.getComputedStyle(body).getPropertyValue("padding-right")
      ) || 0;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.height =
      "auto"; /* fix for sticky elements when html height is 100% */
    html.style.position = "relative"; /* [1] */
    html.style.overflow = "hidden"; /* [2] */
    body.style.position = "relative"; /* [1] */
    body.style.overflow = "hidden"; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  };

  const allowBodyScroll = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!body || !body.style || !scrollBlocked.current) {
      return;
    }

    html.style.height = "";
    html.style.position = "";
    html.style.overflow = "";
    body.style.position = "";
    body.style.overflow = "";
    body.style.paddingRight = "";

    scrollBlocked.current = false;
  };

  return [blockBodyScroll, allowBodyScroll];
};
