import { useCallback } from "react";

// Circular dependency detected when importing directly from @/hooks
import { useToggle } from "@/hooks/useToggle";

type UseModalStateResultType = {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

export const useModalState = (
  defaultValue?: boolean
): UseModalStateResultType => {
  const [isModalOpen, , setIsModalOpen] = useToggle(Boolean(defaultValue));

  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  return {
    isModalOpen,
    openModal,
    closeModal,
  };
};
