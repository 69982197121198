const screens = {
  desktopN: 1366,
  tabletBigN: 1280,
  tabletBigMax: 1279,
  tabletBigMin: 1280,
  tabletLandscapeN: 1024,
  tabletN: 768,
  phoneN: 500,
};

module.exports = {
  screens,
  customMedia: {
    "--media-desktop": `(max-width: ${screens.desktopN}px)`,
    "--media-desktop-min": `(min-width: ${screens.desktopN + 1}px)`,
    "--media-tabletLandscape": `(max-width: ${screens.tabletLandscapeN}px)`,
    "--media-tabletBig": `(max-width: ${screens.tabletBigN}px)`,
    "--media-tablet": `(max-width: ${screens.tabletN}px)`,
    "--media-tablet-min": `(min-width: ${screens.tabletN + 1}px)`,
    "--media-phone": `(max-width: ${screens.phoneN}px)`,
    "--media-phone-min": `(min-width: ${screens.phoneN + 1}px)`,
    "--media-tabletBig-max": `(max-width: ${screens.tabletBigMax}px)`, // TODO
  },
};
