import { useStore } from "effector-react";

import { isPending, maybeData } from "../../lib/remote";
import { $isLoggedIn, $user } from "./effector";

export function useHasVerifiedPhone() {
  return maybeData(
    (user) => user.contact?.contact_status?.phone_verified ?? false,
    false,
    useStore($user)
  );
}
/**
 * Get current user info
 * @returns AccountInfoFieldsFragment | undefined if user is not logged in
 */
export function useUserInfo() {
  return maybeData((user) => user, undefined, useStore($user));
}

/**
 * Get current user info as RemoteData object
 */
export function useUserRemoteData() {
  return useStore($user);
}

export const useIsUserDataLoading = () => isPending(useStore($user));

export function useIsLoggedIn() {
  return useStore($isLoggedIn);
}
