export const getUserInitials = (name: string | string[]) => {
  const results: string[] = [];
  const [firstName, lastName] = Array.isArray(name) ? name : name.split(" ");

  if (firstName) {
    results.push(firstName.slice(0, 1));
  }

  if (lastName) {
    results.push(lastName.slice(0, 1));
  }

  return results.join("").toUpperCase();
};
