import { useIsomorphicLayoutEffect } from "@/hooks/useIsomorphicLayoutEffect";

// TODO: Rename to useLockBodyScroll
export const useBodyOverflowOnLoad = () => {
  useIsomorphicLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);
};
