import { MutableRefObject, useEffect } from "react";

// TODO: Refactor
export const useScrollToSection = (
  id: string | undefined,
  elementRef: MutableRefObject<HTMLDivElement | null>
) => {
  useEffect(() => {
    const hashId = window.location.hash;

    if (id != null && hashId === id && elementRef.current) {
      setTimeout(() => {
        if (elementRef.current != null) {
          elementRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 200);
    }
  }, [elementRef, id]);
};
