import { useEffect } from "react";

export const useBrowserNavigationForMachines = (
  listener: (event: PopStateEvent) => void
) =>
  useEffect(() => {
    window.addEventListener("popstate", listener);

    return () => {
      window.removeEventListener("popstate", listener);
    };
  }, [listener]);
