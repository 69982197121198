/**
 * Extracts a single query param and formats it by passing it to a formatter.
 * Formatter defaults to returning the value as is
 *
 * @param queryParam The query param to extract
 * @param formatter The formatter to apply to the extracted query param
 */
export const extractSingleQueryParam = (
  queryParam: string[] | string | undefined,
  formatter: (value: string | undefined) => string | undefined = (value) =>
    value
) => {
  const extractedSingleQueryParam = Array.isArray(queryParam)
    ? queryParam[0]
    : queryParam;

  return formatter(extractedSingleQueryParam);
};
