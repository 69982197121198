import { useCallback, useEffect, useState } from "react";

import { usePlaidLink } from "react-plaid-link";

type UsePlaidProps = {
  getToken: () => Promise<string | null | undefined>;
  onSuccess: (publicToken: string) => void;
};

export const usePlaid = ({ getToken, onSuccess }: UsePlaidProps) => {
  const [token, setToken] = useState<string | null>(null);

  const getPlaidToken = useCallback(async () => {
    const plaidToken = await getToken();

    if (plaidToken) {
      setToken(plaidToken);
    }
  }, [getToken]);

  const { error, open, ready } = usePlaidLink({
    token,
    onSuccess,
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [open, ready]);

  return {
    error,
    open: getPlaidToken,
  };
};
