import React, { useMemo } from "react";

const HTML_REG_EXP = /<(?:"[^"]*"["']*|'[^']*'["']*|[^"'>])+>/g;

export const useSchemaLdJsonToContent = (schema: string, key: string) =>
  useMemo(
    () => (
      <script
        dangerouslySetInnerHTML={{
          __html: schema.replaceAll(HTML_REG_EXP, ""),
        }}
        key={key}
        type="application/ld+json"
      />
    ),
    [key, schema]
  );
