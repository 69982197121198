import { useMemo } from "react";

import { Maybe } from "@/graphql-schema-types.generated";
import { getUserInitials } from "@/lib/utils";

type UseUserInitialsProps = {
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
};

export const useUserInitials = ({
  firstName,
  lastName,
}: UseUserInitialsProps) =>
  useMemo(
    () => getUserInitials([firstName ?? "", lastName ?? ""]),
    [firstName, lastName]
  );
