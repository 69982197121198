/* eslint-disable prefer-rest-params */

import { useCallback, useEffect } from "react";

import { useQuery, useQueryClient } from "react-query";

import { Routes } from "@/constants";

import { AccountInfoFieldsFragment } from "@/core/Auth/graphql/queries/auth.generated";
import { useUserInfo } from "@/core/Auth/hooks";

const APP_ID = process.env.INTERCOM_CHATBOT_ID;
const MAX_DELAY_LAZY_INTERCOM_INITIALIZATION = 16_000;
const START_PATHNAMES_WITH_DISABLED_DELAY = [
  Routes.Dashboard,
  Routes.Helpcentre,
];

export const useAttachBot = () => {
  const auth = useUserInfo();
  const queryClient = useQueryClient();
  const { data: isIntercomInited } = useQuery("isIntercomInited", () => false);

  const initChat = useCallback(() => {
    // Intercom won't work on localhost. Trusted domains are 0.0.0.0 and 127.0.0.1
    // https://developers.intercom.com/installing-intercom/docs/basic-javascript
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      const pathname = window.location.pathname;
      const DELAY = START_PATHNAMES_WITH_DISABLED_DELAY.some((startPathname) =>
        pathname.startsWith(startPathname)
      )
        ? 0
        : MAX_DELAY_LAZY_INTERCOM_INITIALIZATION;

      setTimeout(() => {
        const d = document;
        const i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        const s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${APP_ID}`;
        document.body.append(s);
        queryClient.setQueryData("isIntercomInited", true);
      }, DELAY);
    }
  }, [queryClient]);

  const bootChat = useCallback(
    (auth: AccountInfoFieldsFragment | undefined) => {
      window.Intercom?.("boot", {
        app_id: APP_ID,
        custom_launcher_selector: "#intercom_chat_link",
        ...(auth && {
          email: auth.contact?.email_primary ?? "",
          name: `${auth.details?.first_name ?? ""} ${
            auth.details?.last_name ?? ""
          }`,
          created_at: new Date(auth.created_at ?? "").getTime() / 1000,
        }),
      });
    },
    []
  );

  useEffect(() => {
    const isDocumentComplete = document.readyState === "complete";

    if (isDocumentComplete) {
      initChat();
    }

    if (!isDocumentComplete) {
      window.addEventListener("load", initChat);

      return () => {
        window.removeEventListener("load", initChat);
      };
    }

    return undefined;
  }, [initChat]);

  useEffect(() => {
    if (!isIntercomInited) {
      return;
    }
    bootChat(auth);
  }, [auth, bootChat, isIntercomInited]);
};
