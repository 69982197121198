import { extractSingleQueryParam } from "./extractSingleQueryParam";
import { replaceDashWithSpace } from "./replaceDashWithSpace";

/**
 * Extracts a single query param and formats it by replacing dashes with spaces
 *
 * @param queryParam - The query param to extract and format
 */
export const extractAndFormatSingleQueryParam = (
  queryParam: string[] | string | undefined
) => extractSingleQueryParam(queryParam, replaceDashWithSpace);
