import { useMemo } from "react";

import { Maybe } from "@/graphql-schema-types.generated";

type UseUserNameProps = {
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
};

export const useUserName = ({ firstName, lastName }: UseUserNameProps) =>
  useMemo(
    () => [firstName, lastName].filter(Boolean).join(" "),
    [firstName, lastName]
  );
