import { useEffect, useState } from "react";

const INTERVAL_DELAY = 60_000;

export const useCountdown = (endTime: number) => {
  const [timeLeft, setTimeLeft] = useState(endTime - Date.now());

  useEffect(() => {
    const update = () => setTimeLeft(endTime - Date.now());

    const timerId = setInterval(update, INTERVAL_DELAY);

    return () => clearInterval(timerId);
  }, [endTime]);

  return timeLeft;
};
