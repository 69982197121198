import { SyntheticEvent } from "react";

import HtmlParser, { Options } from "react-html-parser";

import { DashboardOverviewOfferDetailsFragment } from "@/features/private/dashboard/organisms/LandlordOverview/index.generated";
import { Offer_Api_DepositTypeEnum } from "@/graphql-schema-types.generated";

export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export function get<T>(value = {}, path = "", fallback: T): T {
  let result = value;

  const parts = path.split(".");

  if (parts.length > 0) {
    while (parts.length > 0) {
      result = (result as any)[parts.splice(0, 1)[0]];
    }
  }

  return typeof result !== "undefined" ? (result as T) : fallback;
}

export function scrollToPosition({ to }: { to: number }) {
  window.scrollTo(0, to);
}

export const scrollToElement =
  (id: string, offset = 0) =>
  (e: SyntheticEvent<unknown>) => {
    e.preventDefault();
    // eslint-disable-next-line unicorn/prefer-query-selector
    const element = document.getElementById(id);
    if (element != null) {
      const elementPosition = element.offsetTop - offset;
      window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth",
      });
    }
  };

export function trimLeft(string: string, character: string) {
  const first = [...(string as any)].findIndex((char) => char !== character);
  return string.slice(first, string.length);
}

export function trimRight(string: string, character: string) {
  const last = [...(string as any)]
    .reverse()
    .findIndex((char) => char !== character);
  return string.slice(0, Math.max(0, string.length - last));
}

export const makeAbsoluteUrl = (path: string) =>
  `${trimRight(process.env.VHOST ?? "", "/")}/${trimLeft(path, "/")}`;

export const calcPricePerWeek = (price: number) =>
  Math.floor((price * 12) / 52);

export const calcDepositAmount = (
  rental: number,
  type: Offer_Api_DepositTypeEnum,
  numberFractionDigits = 2
) => {
  const oneWeekRent = (rental * 12) / 52;
  if (type === Offer_Api_DepositTypeEnum.DepositReplacement) {
    return Number((oneWeekRent * 1.2).toFixed(numberFractionDigits));
  } else if (type === Offer_Api_DepositTypeEnum.DepositTraditional_5Week) {
    return Number((oneWeekRent * 5).toFixed(numberFractionDigits));
  } else {
    return 0;
  }
};

export const calcDRPPaymentsCount = (
  rentTerm: number,
  breakClauseTerm: number
): number => {
  // [MRM-2988]
  // The logic remains that if the user has a break clause, the number of instalments should be equal to
  // the number of months that the break clause becomes active on. E.g. if the user has a 12 month tenancy,
  // but a break clause at 6 months, then they need to pay their deposit replacement fee in 6 monthly instalments.

  // 12 is the maximum number of monthly instalments offered. So if the user has a tenancy that is
  // longer than 12 months, the deposit replacement fee will be split across 12 monthly payments.
  // E.g. if the user has a 24 month tenancy, they will pay for deposit replacement in 12 instalments.instalments
  if (breakClauseTerm > 0) {
    return Math.min(breakClauseTerm, 12);
  } else {
    return Math.min(rentTerm, 12);
  }
};

export const calcDRPMonthlyPayment = (
  DRPPrice: number,
  rentTerm: number,
  breakClauseTerm: number,
  numberFractionDigits?: number
): {
  monthlyPayment: number;
  paymentsCount: number;
} => {
  const paymentsCount = calcDRPPaymentsCount(rentTerm, breakClauseTerm);
  const monthlyPayment = DRPPrice / paymentsCount;

  return {
    monthlyPayment:
      typeof numberFractionDigits === "number"
        ? Number(monthlyPayment.toFixed(numberFractionDigits))
        : monthlyPayment,
    paymentsCount,
  };
};

export const calcTenantsCheckPrice = (
  tenants: NonNullable<DashboardOverviewOfferDetailsFragment["tenants"]>,
  guarantors: NonNullable<DashboardOverviewOfferDetailsFragment["guarantors"]>
) => (tenants.length + guarantors.length) * 15;

export const flattenVerificationObject = <T>(
  res: { [key: string]: T[] },
  path: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  obj: Object
) => {
  if (Array.isArray(obj) && obj.length === 0) {
    //Ignoring empty errors array — no errors
  } else if (
    Array.isArray(obj) &&
    obj[0] != null &&
    typeof obj[0] === "string"
  ) {
    res[path] = obj as T[];
  } else if (Array.isArray(obj)) {
    for (const [idx, elem] of obj.entries()) {
      flattenVerificationObject(res, `${path}.${idx}`, elem);
    }
  } else if (typeof obj === "object") {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    for (const key of Object.keys(obj ?? {})) {
      flattenVerificationObject(
        res,
        path !== "" ? `${path}.${key}` : key,
        obj[key]
      );
    }
  }
};

export function getYouTubeID(url: string) {
  let ID = "";
  const _url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (_url[2] != null) {
    ID = _url[2].split(/[^\w-]/i)[0];
  } else {
    ID = url;
  }
  return ID;
}

export const rn2br = (html: string) => html.split("\n").join("<br />");

export const parseHtml = (
  html: string | undefined | null,
  { rn2br: isRn2Br, ...options }: { rn2br?: boolean } & Options = {}
) => {
  const Html = String(html).trim();
  return HtmlParser(isRn2Br ? rn2br(Html) : Html, options);
};

export const pluralizeString = (
  count: number,
  noun: string,
  suffix = "s"
): string => `${noun}${count !== 1 ? suffix : ""}`;

const pluralRulesEn = new Intl.PluralRules("en");
export const pluralizeEn = (
  count: number,
  variants: Record<"one" | "other", string>
) => variants[pluralRulesEn.select(count)];

// Hack needed to avoid JSON-Serialization validation error from Next.js https://github.com/zeit/next.js/discussions/11209
// >>> Reason: `undefined` cannot be serialized as JSON. Please use `null` or omit this value all together.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteUndefined = (obj: Record<string, any> | undefined): void => {
  if (obj) {
    // eslint-disable-next-line unicorn/no-array-for-each
    Object.keys(obj).forEach((key: string) => {
      if (obj[key] && typeof obj[key] === "object") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        deleteUndefined(obj[key]);
      } else if (typeof obj[key] === "undefined") {
        delete obj[key]; // eslint-disable-line no-param-reassign
      }
    });
  }
};

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const metersToMiles = (distance: number, accuracy = 1) =>
  (distance / 1609).toFixed(accuracy);

export function addToUniqueList<T>(
  list: readonly T[] | undefined | null,
  elem: T | readonly T[]
): T[] {
  const set = new Set(list);
  if (Array.isArray(elem)) {
    for (const e of elem as T[]) {
      set.add(e);
    }
  } else {
    set.add(elem as T);
  }
  return [...set];
}
