import { useCallback } from "react";
import { openPopupWidget } from "react-calendly";

import { useUserInfo } from "../core/Auth/hooks";

export const useOpenPopupCalendly = (url: string) => {
  const user = useUserInfo();

  return useCallback(() => {
    openPopupWidget({
      url,
      prefill: {
        email: user?.contact?.email_primary ?? "",
        firstName: user?.details?.first_name ?? "",
        lastName: user?.details?.last_name ?? "",
        name: [user?.details?.first_name ?? "", user?.details?.last_name ?? ""]
          .join(" ")
          .trim(),
      },
    });
  }, [
    url,
    user?.contact?.email_primary,
    user?.details?.first_name,
    user?.details?.last_name,
  ]);
};
