import { Dispatch, SetStateAction, useEffect, useState } from "react";

type UseResetWithTimeoutResultType = [
  boolean,
  Dispatch<SetStateAction<boolean>>
];

const DEFAULT_TIMEOUT_DELAY = 3000;

// TODO: should be renamed?
export const useResetWithTimeout = (
  delay = DEFAULT_TIMEOUT_DELAY
): UseResetWithTimeoutResultType => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timer;

    if (copied) {
      timer = setTimeout(() => {
        setCopied(false);
      }, delay);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };
  }, [copied, delay]);

  return [copied, setCopied];
};
